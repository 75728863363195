@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: $FONT-WEIGHT-REGULAR;
  font-display: swap;
  src: url('../Assets/Webfonts/Inter-Regular.woff2') format('woff2'),
    url('../Assets/Webfonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: $FONT-WEIGHT-BOLD;
  font-display: swap;
  src: url('../Assets/Webfonts/Inter-Bold.woff2') format('woff2'),
    url('../Assets/Webfonts/Inter-Bold.woff') format('woff');
}
