@import 'Styles/constants';

.NotFound {
  padding: 128px 0;
  text-align: center;
}

.NotFound-Title {
  margin: 0;
  font-size: 26px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 36px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .NotFound-Title {
    font-size: 32px;
    line-height: 48px;
  }
}
