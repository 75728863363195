////
// Colors
//

// Global

$COLOR-DARK: #081121;
$COLOR-LIGHT: #f7f9f9;

$COLOR-BLUE-DARK: #142b54;
$COLOR-BLUE-LIGHT: #0088f1;
$COLOR-BLUE-EXTRA-LIGHT: #028bec;

$COLOR-GREEN-DARK: #184940;
$COLOR-GREEN-LIGHT: #6c8b85;
$COLOR-GREEN-EXTRA-LIGHT: #169945;
$COLOR-GREEN-SUPER-LIGHT: #d2dedb;

$COLOR-YELLOW-DARK: #ffba17;
$COLOR-YELLOW-LIGHT: #ffd068;

$COLOR-PINK-DARK: #d5877f;

$COLOR-RED-DARK: #ea002f;
$COLOR-RED-EXTRA-LIGHT: #ffdee5;

// Projects

$COLOR-BLUE-SIMPLE: #3371ad;

////
// Fonts
//

$FONT-FAMILY-INTER: 'Inter', Helvetica, Arial, sans-serif;

$FONT-WEIGHT-REGULAR: 400;
$FONT-WEIGHT-BOLD: 700;

////
// Breakpoints
//

$BREAKPOINT-SMALL: 480px;
$BREAKPOINT-MEDIUM: 910px;

////
// Dimensions
//

$HEADER-HEIGHT: 138px;

$LAYOUT-MAX-WIDTH: 1296px;

$LAYOUT-PADDING-X--XS: 32px;
$LAYOUT-PADDING-X--S: 48px;
