////
// Margin
//

@each $size in (0, 32, 48, 64) {
  .Util-MT\(#{$size}\) {
    margin-top: #{$size}px !important;
  }
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  @each $size in (0, 48, 64) {
    .Util-MT\(#{$size}\)\@M {
      margin-top: #{$size}px !important;
    }
  }
}

.Util-MX\(a\) {
  margin-left: auto !important;
  margin-right: auto !important;
}
