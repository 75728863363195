@import 'Styles/constants';

.Layout {
  padding: 0;
}

.Layout--Frame {
  max-width: $LAYOUT-MAX-WIDTH;
  padding-left: $LAYOUT-PADDING-X--XS;
  padding-right: $LAYOUT-PADDING-X--XS;
  margin: auto;
  box-sizing: border-box;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Layout--Frame {
    padding-left: $LAYOUT-PADDING-X--S;
    padding-right: $LAYOUT-PADDING-X--S;
  }
}

.Layout--Frame .Layout-Main {
  padding-top: $HEADER-HEIGHT;
  padding-bottom: 128px;
}
