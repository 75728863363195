.Image {
  display: block;
}

.Image--Smooth {
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.Image--Fluid {
  width: 100%;
  height: auto;
}

.Image--Scaled {
  width: auto;
  max-width: 100%;
  height: auto;
}
