@import 'Styles/constants';

.Header {
  width: 100%;
  max-width: $LAYOUT-MAX-WIDTH;
  padding: 40px $LAYOUT-PADDING-X--XS 64px;
  margin: auto;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Header {
    padding-left: $LAYOUT-PADDING-X--S;
    padding-right: $LAYOUT-PADDING-X--S;
  }
}

.Header--Light {
  color: $COLOR-LIGHT;
}

.Header--MenuOpen {
  color: $COLOR-GREEN-DARK;
  z-index: 1000;
}

.Header-Logo {
  font-size: 20px;
  line-height: 24px;
  position: relative;
  z-index: 200;
}

.Header--MenuOpen {
  position: fixed;
}

.Header-Link {
  color: inherit;
  text-decoration: none;
}

.Nav {
  margin-left: auto;
  display: none;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Nav {
    display: block;
  }
}

.Nav-List {
  display: flex;
}

.Nav-ListItem {
  padding-left: 48px;
  font-size: 16px;
  line-height: 24px;
}

.Nav-Link {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  color: inherit;
  text-decoration: none;
}

.Nav-Link::after {
  content: '';
  width: 20px;
  height: 2px;
  position: absolute;
  bottom: 0;
  background: $COLOR-YELLOW-DARK;
  transform: scaleX(0);
  transition: transform 0.15s ease-in-out;
}

.Nav-Link:hover::after {
  transform: scaleX(1);
}

.Nav--SectionWork .Nav-Link--SectionWork::after,
.Nav--SectionAbout .Nav-Link--SectionAbout::after,
.Nav--SectionContact .Nav-Link--SectionContact::after {
  transform: scaleX(1);
}

.Menu {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}

.Header--MenuOpen .Menu {
  position: fixed;
  left: 0;
}

.Menu-Cover {
  width: 50px;
  height: 50px;
  position: fixed;
  right: -50px;
  top: -50px;
  border-radius: 50%;
  background: $COLOR-YELLOW-DARK;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.Header--MenuOpen .Menu-Cover {
  transform: scale(70);
  transition: transform 0.9s ease;
}

.Menu-Hamburger {
  width: 20px;
  height: 14px;
  position: absolute;
  top: 45px;
  right: $LAYOUT-PADDING-X--XS;
  cursor: pointer;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Menu-Hamburger {
    right: $LAYOUT-PADDING-X--S;
  }
}

.Menu-Hamburger:focus {
  outline: 0;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Menu-Hamburger {
    display: none;
  }
}

.Menu-HamburgerLine {
  height: 2px;
  background: $COLOR-DARK;
  display: inline-block;
  position: absolute;
  right: 0;
  transition: transform 0.4s ease, background-color 0.4s ease;
}

.Header--Light .Menu-HamburgerLine {
  background: $COLOR-LIGHT;
}

.Header--MenuOpen .Menu-HamburgerLine {
  background: $COLOR-GREEN-DARK;
}

.Menu-HamburgerLine--1 {
  width: 75%;
  top: 0;
}

.Menu-HamburgerLine--2 {
  width: 97%;
  top: 6px;
}

.Menu-HamburgerLine--3 {
  width: 55%;
  top: 12px;
}

.Header--MenuOpen .Menu-HamburgerLine {
  width: 100%;
}

.Header--MenuOpen .Menu-HamburgerLine--1 {
  transform: rotate(135deg);
  top: 6px;
}

.Header--MenuOpen .Menu-HamburgerLine--2 {
  opacity: 0;
}

.Header--MenuOpen .Menu-HamburgerLine--3 {
  transform: rotate(-135deg);
  top: 6px;
}

.Menu-List {
  width: 0;
  height: 0;
  position: absolute;
  left: $LAYOUT-PADDING-X--XS;
  bottom: 40px;
  font-size: 24px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 36px;
  transform: translateY(200px);
  opacity: 0;
  overflow: hidden;
  transition: transform 0.4s ease, opacity 0.4s ease;
  transition-delay: 0.2s;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Menu-List {
    left: $LAYOUT-PADDING-X--S;
  }
}

.Header--MenuOpen .Menu-List {
  width: auto;
  height: auto;
  transform: translateY(0);
  opacity: 1;
}

.Menu-ListItem {
  margin-top: 16px;
}

.Menu-Link {
  color: inherit;
  text-decoration: none;
}
