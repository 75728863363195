@import 'Styles/constants';

@media (min-width: $BREAKPOINT-MEDIUM) {
  .About {
    margin-top: 26px;
  }
}

.About-Me {
  padding-bottom: 68px;
  position: relative;
}

.About-Me::after {
  content: '';
  width: 96px;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: $COLOR-YELLOW-DARK;
}

.About-Title {
  font-size: 40px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 48px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .About-Title {
    font-size: 60px;
    line-height: 72px;
  }
}

.About-Paragraph {
  margin-top: 16px;
  font-size: 16px;
  line-height: 28px;
}

.Resume {}

.Resume-Entry {
  margin-top: 64px;
}

.Resume-Company {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: $FONT-WEIGHT-REGULAR;
  line-height: 24px;
}

.Resume-Period {
  font-size: 16px;
  line-height: 24px;
  color: $COLOR-GREEN-LIGHT;
}

.Resume-Position {
  margin-top: 16px;
  font-size: 26px;
  line-height: 30px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Resume-Position {
    margin-top: 0;
  }
}

.Resume-Overview {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
}

.Resume-Details {}

.Resume-Detail {
  margin-top: 16px;
  padding-left: 36px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
}

.Resume-Detail::before {
  content: '•';
  font-size: 18px;
  position: absolute;
  top: -1px;
  left: 12px;
}

.Resume-Download {
  margin-top: 64px;
  text-align: center;
}
