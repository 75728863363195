@import 'Styles/constants';

////
// 1. Visually remove gutter from first cell.
//

.Grid {
  margin-left: -24px; // 1
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Grid {
    margin-left: -48px; // 1
  }
}

.Grid--Center {
  align-items: center;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Grid--Reverse {
    flex-direction: row-reverse;
  }
}

////
// 21. Gutter.
//

.Grid-Cell {
  width: 100%;
  padding-left: 24px; // 1
  box-sizing: border-box;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Grid-Cell {
    padding-left: 48px; // 1
  }
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  @for $num from 1 through 7 {
    .Grid-Cell--Size\(#{$num}\/8\) {
      width: percentage($num / 8);
    }

    .Grid-Cell--Offset\(#{$num}\/8\) {
      margin-left: percentage($num / 8);
    }

    .Grid--Reverse .Grid-Cell--Offset\(#{$num}\/8\) {
      margin-left: 0;
      margin-right: #{percentage($num / 8)};
    }
  }
}
