@import 'Styles/constants';

////
// 1. Reset default `button` styles.
//

.Button {
  padding: 0 4px; // 1
  display: inline-block; // 1
  position: relative;
  z-index: 1;
  font: inherit; // 1
  font-size: 16px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 24px;
  color: $COLOR-GREEN-DARK;
  text-align: left; // 1
  text-decoration: none;
  border: none; // 1
  background: transparent; // 1
  cursor: pointer;
}

.Button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.Button:focus {
  outline: none;
}

.Button-Border {
  width: 100%;
  height: 6px;
  position: absolute;
  left: 0;
  bottom: 3px;
}

.Button-Border--Top {
  z-index: -1;
  background: $COLOR-YELLOW-DARK;
  transform: scaleX(0);
  transition: transform 0.15s ease-in-out;
}

.Button:hover .Button-Border--Top {
  transform: scaleX(1);
}

.Button-Border--Bottom {
  z-index: -2;
  background: $COLOR-GREEN-DARK;
  opacity: 0.25;
}
