@import 'Styles/constants';

.Project {}

.Project-Intro {
  text-align: center;
}

.Project-Picture {}

.Project-Title {
  margin-top: 32px;
  font-size: 40px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 56px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-Title {
    margin-top: 56px;
    font-size: 60px;
    line-height: 88px;
  }
}

.Project--NameGo .Project-Title {
  color: $COLOR-BLUE-EXTRA-LIGHT;
}

.Project--NameTestd .Project-Title {
  color: $COLOR-GREEN-EXTRA-LIGHT;
}

.Project--NameBeverlly .Project-Title {
  color: $COLOR-PINK-DARK;
}

.Project--NameSimple .Project-Title {
  color: $COLOR-BLUE-SIMPLE;
}

.Project-TitleWord {
  font-weight: $FONT-WEIGHT-REGULAR;
}

.Project-SubTitle {
  margin-top: 16px;
  font-size: 24px;
  font-weight: $FONT-WEIGHT-REGULAR;
  line-height: 36px;
}

.Project-Details {}

.Project-Section {
  margin-top: 64px;
}

.Project-Heading {
  font-size: 26px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 36px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-Heading {
    font-size: 32px;
    line-height: 48px;
  }
}

.Project-Heading--2 {
  font-size: 16px;
  line-height: 28px;
}

.Project-Paragraph {
  margin-top: 16px;
  font-size: 16px;
  line-height: 28px;
}

.Project-List {}

.Project-ListItem {
  margin-top: 16px;
  padding-left: 36px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
}

.Project-ListItem::before {
  content: '•';
  font-size: 18px;
  position: absolute;
  top: -1px;
  left: 12px;
}

.Project-Tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-Tools {
    justify-content: space-between;
  }
}

.Project-Tool {
  margin: 48px 24px 0;
  text-align: center;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-Tool {
    margin-left: 0;
    margin-right: 0;
  }
}

.Project-ToolItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Project-ToolImage {
  margin: auto;
}

.Project-ToolName {
  margin-top: 26px;
  font-size: 16px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 20px;
  white-space: nowrap;
}

.Project-Figure {
  margin-top: 48px;
}

.Project-FigureImage {}

.Project-FigureCaption {
  padding: 0 8px;
  margin-top: 26px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  color: $COLOR-GREEN-LIGHT;
}

.Project-Quote {
  margin-top: 48px;
  display: inline-block;
}

.Project-QuoteText {
  padding: 14px 0;
  position: relative;
  z-index: 1;
  font-size: 24px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 34px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-QuoteText {
    padding: 20px 0;
    font-size: 36px;
    line-height: 44px;
  }
}

.Project-QuoteText::before,
.Project-QuoteText::after {
  position: absolute;
  font-size: 106px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 1;
  color: rgba($COLOR-BLUE-EXTRA-LIGHT, 0.5);
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-QuoteText::before,
  .Project-QuoteText::after {
    font-size: 148px;
  }
}

.Project-QuoteText::before {
  content: '\201C';
  top: -10px;
  left: 0;
  z-index: -1;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-QuoteText::before {
    top: -14px;
  }
}

.Project-QuoteText::after {
  content: '\201D';
  bottom: -64px;
  right: 0;
  z-index: -1;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Project-QuoteText::after {
    bottom: -90px;
  }
}

.Project-QuoteSource {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
