@import 'Styles/constants';

.Work {
  display: block;
  text-decoration: none;
  color: $COLOR-DARK;
}

.Work-Picture {
  overflow: hidden;
  border: 1px solid rgba($COLOR-GREEN-LIGHT, 0.5);
}

.Work-Image {
  transition: transform 0.15s ease-in-out;
}

.Work:hover .Work-Image {
  transform: scale(1.05);
}

.Work-Title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: $FONT-WEIGHT-REGULAR;
  line-height: 20px;
}
