@import 'Styles/constants';

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Contact {
    margin-top: 26px;
  }
}

.Contact-Title {
  font-size: 40px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 48px;
  text-align: center;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Contact-Title {
    font-size: 60px;
    line-height: 72px;
  }
}

.Data {
  margin-top: 64px;
  padding-bottom: 68px;
  position: relative;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Data {
    width: 264px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Data::after {
  content: '';
  width: 96px;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: $COLOR-YELLOW-DARK;
}

.Data-Title:not(:first-child) {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid rgba($COLOR-GREEN-LIGHT, 0.5);
}

.Data-Detail {
  margin-top: 8px;
}

.Data-Link {
  font-weight: $FONT-WEIGHT-BOLD;
  text-decoration: none;
  color: $COLOR-GREEN-DARK;
}

.Form {
  margin-top: 64px;
}

.Form-Group {
  margin-bottom: 24px;
}

.Form-Label {
  display: block;
}

.Form-Optional {
  color: $COLOR-GREEN-LIGHT;
}

.Form-Field {
  width: 100%;
  padding: 6px 8px;
  margin-top: 8px;
  display: block;
  box-sizing: border-box;
  font: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: $FONT-WEIGHT-BOLD;
  background: $COLOR-LIGHT;
  border: 1px solid rgba($COLOR-GREEN-LIGHT, 0.5);
}

.Form-Field:focus {
  padding: 5px 7px;
  border: 2px solid $COLOR-GREEN-DARK;
  outline: 0;
}

.Form-Field--TextArea {
  height: 160px;
  resize: none;
}

.Form-Field--Error,
.Form-Field--Error:focus {
  padding: 5px 7px;
  border: 2px solid $COLOR-RED-DARK;
}

.Form-FieldMessage {
  margin-top: 4px;
  font-size: 16px;
  line-height: 24px;
}

.Form-FieldMessage--Error {
  color: $COLOR-RED-DARK;
}

.Form-Message {
  width: max-content;
  margin: 16px auto 0;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 24px;
}

.Form-Message--Error {
  color: $COLOR-RED-DARK;
  background: $COLOR-RED-EXTRA-LIGHT;
}

.Form-Message--Success {
  color: $COLOR-GREEN-DARK;
  background: $COLOR-GREEN-SUPER-LIGHT;
}

.Form-MessageIcon {
  margin-right: 8px;
}

.Form-Button {
  text-align: center;
}

