////
// 1. Prevent "shaking" effect due to presence/absence of scrollbars.
//

body {
  margin: 0;
  font-family: $FONT-FAMILY-INTER;
  font-size: 16px;
  font-weight: $FONT-WEIGHT-REGULAR;
  line-height: 1.75;
  color: $COLOR-DARK;
  background: $COLOR-LIGHT;
  overflow-y: scroll; // 1
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

////
// 1. Correct the line height in all browsers.
// 2. Prevent adjustments of font size after orientation changes in iOS.
//

html {
  line-height: 1.15; // 1
  -webkit-text-size-adjust: 100%; // 2
}

////
// Remove the gray background on active links in IE 10.
//

a {
  background-color: transparent;
}

////
// Add the correct font weight in Chrome, Edge, and Safari.
//

b,
strong {
  font-weight: bolder;
}

////
// 1. Correct the inheritance and scaling of font size in all browsers.
// 2. Correct the odd `em` font sizing in all browsers.
//

////
// Add the correct font size in all browsers.
//

small {
  font-size: 80%;
}

////
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

////
// Remove the border on images inside links in IE 10.
//

img {
  border-style: none;
}

////
// 1. Change the font styles in all browsers.
// 2. Remove the margin in Firefox and Safari.
//

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; // 1
  font-size: 100%; // 1
  line-height: 1.15; // 1
  margin: 0; // 2
}

////
// Show the overflow in IE.
// 1. Show the overflow in Edge.
//

button,
input { // 1
  overflow: visible;
}

////
// Remove the inheritance of text transform in Edge, Firefox, and IE.
// 1. Remove the inheritance of text transform in Firefox.
//

button,
select { // 1
  text-transform: none;
}

////
// Correct the inability to style clickable types in iOS and Safari.
//

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

////
// Remove the inner border and padding in Firefox.
//

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

////
// Restore the focus styles unset by the previous rule.
//

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

////
// Remove the default vertical scrollbar in IE 10+.
//

textarea {
  overflow: auto;
}

////
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
  -webkit-appearance: button; // 1
  font: inherit; // 2
}

////
// Removes the default spacing and border for appropriate elements.
//

blockquote,
dl,
dd,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
}

////
// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
