@import 'Styles/constants';

.Cover {
  min-height: 100vh;
  display: flex;
  align-items: center;
  color: $COLOR-LIGHT;
  background: $COLOR-GREEN-DARK;
}

.Cover-Wrapper {
  width: 100%;
  max-width: $LAYOUT-MAX-WIDTH;
  padding: $HEADER-HEIGHT $LAYOUT-PADDING-X--XS;
  margin: auto;
  box-sizing: border-box;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Cover-Wrapper {
    padding-left: $LAYOUT-PADDING-X--S;
    padding-right: $LAYOUT-PADDING-X--S;
  }
}

.Cover-Text {
  max-width: 320px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Cover-Text {
    max-width: 425px;
  }
}

.Cover-Title {
  font-size: 40px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 48px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Cover-Title {
    font-size: 60px;
    line-height: 72px;
  }
}

.Cover-Flair {
  margin-top: 16px;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 20px;
  border-bottom: 1px solid $COLOR-GREEN-LIGHT;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Cover-Flair {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Cover-Flair {
    font-size: 32px;
    line-height: 38px;
  }
}

.Cover-Cursor {
  font-weight: $FONT-WEIGHT-REGULAR;
}

.Cover-Expertise {
  margin-top: 24px;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Cover-Expertise {
    font-size: 20px;
    line-height: 24px;
  }
}

.Cover-Location {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Cover-Location {
    margin-top: 16px;
    font-size: 20px;
    line-height: 24px;
  }
}

.Cover-Icon {
  width: 8px;
  margin-right: 8px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Cover-Icon {
    width: 12px;
    margin-right: 10px;
  }
}

.Splash {
  display: flex;
  align-items: center;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Splash {
    min-height: 100vh;
  }

  .Splash--ProjectTestd {
    min-height: auto;
  }
}

.Splash--ProjectGo {
  color: $COLOR-DARK;
  background: $COLOR-LIGHT;
}

.Splash--ProjectTestd {
  text-align: center;
  color: $COLOR-GREEN-DARK;
  background: $COLOR-YELLOW-LIGHT;
}

.Splash--ProjectBeverlly {
  color: $COLOR-LIGHT;
  background: $COLOR-BLUE-DARK;
}

.Splash-Wrapper {
  width: 100%;
  max-width: $LAYOUT-MAX-WIDTH;
  padding: 64px $LAYOUT-PADDING-X--XS;
  margin: auto;
  box-sizing: border-box;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Splash-Wrapper {
    padding-left: $LAYOUT-PADDING-X--S;
    padding-right: $LAYOUT-PADDING-X--S;
  }
}

.Splash--ProjectTestd .Splash-Wrapper {
  padding-bottom: 0;
}

.Splash-Text {}

.Splash-Title {
  height: 96px;
  font-size: 48px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 96px;
  overflow: hidden;
  word-break: break-word;
}

.Splash--ProjectBeverlly .Splash-Title {
  font-size: 42px;
}

@media (min-width: $BREAKPOINT-SMALL) {
  .Splash--ProjectBeverlly .Splash-Title {
    font-size: 48px;
  }
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Splash-Title {
    font-size: 60px;
  }
}

.Splash--ProjectGo .Splash-Title {
  color: $COLOR-BLUE-EXTRA-LIGHT;
}

.Splash--ProjectTestd .Splash-Title {
  color: $COLOR-GREEN-EXTRA-LIGHT;
}

.Splash--ProjectBeverlly .Splash-Title {
  color: $COLOR-LIGHT;
}

.Splash-SubTitle {
  font-size: 26px;
  font-weight: $FONT-WEIGHT-BOLD;
  line-height: 36px;
  color: inherit;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Splash-SubTitle {
    font-size: 32px;
    line-height: 48px;
  }
}

.Splash-Paragraph {
  margin-top: 16px;
  font-size: 16px;
  line-height: 28px;
  color: inherit;
}

.Splash-Link {
  margin-top: 24px;
}

.Splash-Picture {
  margin-top: 48px;
}

@media (min-width: $BREAKPOINT-MEDIUM) {
  .Splash-Picture {
    margin-top: 0;
  }
}

.Splash--ProjectTestd .Splash-Image {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
